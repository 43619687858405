import { socialLoginUrls } from 'src/environments/environment'
import { IAlertService } from 'src/app/libs/ialert/ialerts.service'
import { Component, OnInit, TemplateRef } from '@angular/core'
import { Router, ActivatedRoute } from '@angular/router'
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms'
import { UIHelpers } from 'src/app/helpers/ui-helpers'
import { ConstantsService } from 'src/app/services/constants.service'
import { DataService } from './data.service'
import { apis } from '../../../../environments/environment'
import { HttpClient } from '@angular/common/http'
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal'
import { DomSanitizer, SafeHtml } from '@angular/platform-browser'
@Component({
    selector: 'app-registration',
    templateUrl: './registration.component.html',
    styleUrls: ['./registration.component.css']
})
export class RegistrationComponent implements OnInit {
    regForm: FormGroup
    htmlContent: SafeHtml
    termsAndCondition: any
    maxDate: string
    role = 'parallel'
    loadingStatus = false
    loginLoading = false
    lang: any
    teamList: any = []
    modalRef: BsModalRef
    constructor(
        private ds: DataService,
        private router: Router,
        public ui: UIHelpers,
        private alert: IAlertService,
        public cs: ConstantsService,
        private route: ActivatedRoute,
        private http: HttpClient,
        private ms: BsModalService,
        private sanitizer: DomSanitizer
    ) { }
    ngOnInit() {
        this.loadingStatus = true
        this.regForm = new FormGroup({
           
            email: new FormControl(null, [Validators.required, Validators.email]),
            contact: new FormControl(null, [
                Validators.required,
                Validators.maxLength(15)
            ]),
            firstName: new FormControl(null, [Validators.required, Validators.maxLength(200)]),
            lastName: new FormControl(null, [Validators.maxLength(200)]),
            //house: new FormControl(null, [Validators.maxLength(100)]),
            //street: new FormControl(null, [Validators.maxLength(100)]),
            city: new FormControl(null, [Validators.maxLength(100)]),
            //state: new FormControl(null, [Validators.maxLength(100)]),
            zipCode: new FormControl(null, [Validators.maxLength(100)]),
            
            password: new FormControl(null, [Validators.required]),
            password_confirmation: new FormControl(null, [Validators.required]),
            agreed_to_terms: new FormControl(null, [Validators.required]),
           address: new FormControl(null, [Validators.required, Validators.maxLength(100)]),
           addressTwo: new FormControl(null)
        })
    }
    get g() {
        return this.regForm.controls
    }

    onChange() {
        if (!this.g.agreed_to_terms.value) {
            this.g.agreed_to_terms.setValue(null)
        }
    }

    openModal(termsAndConditionModal: TemplateRef<any>) {
        this.loadingStatus = true
        this.ds.termsAndCondition().subscribe((resp) => {
            this.loadingStatus = false
            if (resp.success) {
                this.htmlContent = resp.data.content
            } else {
                this.alert.error(resp.errors.general || 'Error')
            }
        })
        this.modalRef = this.ms.show(termsAndConditionModal, {
            class: 'modal-md modal-dialog-centered',
            backdrop: 'static',
            ignoreBackdropClick: true,
            keyboard: false
        })
    }
    agree() {
        this.modalRef.hide()
        setTimeout(() => {
            this.g.agreed_to_terms.setValue(true)
        }, 500)
    }
    register(): void {
        this.loginLoading = true

        if (this.regForm.invalid || this.role == '') {
            if (this.regForm.status == 'INVALID') {
                this.alert.error('Please fill in complete data & then try again')
                this.loginLoading = false
                return
            }
        }
        if (this.regForm.value.password !== this.regForm.value.password_confirmation) {
            this.alert.error('Password does not match')
            this.loginLoading = false
            return
        }


        // this.http.post(`${baseUrl}/public/register`, regData).subscribe((data: any) => {
        //     this.loginLoading = false
        //     if (data.success == false) {
        //         this.alert.error('Email is already exist')
        //         return
        //     }
        // })

        this.ds.registration(this.regForm.value).subscribe((resp: any) => {
            this.loginLoading = false
            if (resp.success) {
                this.alert.success('Registeration Successful!')
                this.router.navigate(['/registration-success'])
            } else {
                this.alert.error(resp.errors.general)
            }
        })
    }
}
