import { SharedModule } from './../../shared/shared.module'
import { ReactiveFormsModule } from '@angular/forms'
import { DataService } from './data.service'
import { NgModule } from '@angular/core'
import { RegistrationComponent } from './registration.component'
import { RouterModule } from '@angular/router'
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker'
import { NgSelectModule } from '@ng-select/ng-select'
import { NgxMaskModule } from 'ngx-mask'
import { ModalModule } from 'ngx-bootstrap/modal'

@NgModule({
    imports: [
        SharedModule,
        NgSelectModule,
        ReactiveFormsModule,
        ModalModule.forRoot(),
        NgxMaskModule.forChild(),
        BsDatepickerModule.forRoot(),
        RouterModule.forChild([{ path: '', component: RegistrationComponent }])
    ],
    declarations: [RegistrationComponent],
    providers: [DataService]
})
export class RegistrationModule {}
