<div class="signin-container">
    <div class="box-container">
        <div class="left-box">
            <img src="assets/images/arizona-parallel-logo-modified.png" alt="logo">
        </div>
        <div class="right-box">
            <h1 class="title">Registration</h1>
            <form [formGroup]="regForm" (ngSubmit)="register()" #f="ngForm">
                
                <div class="input-container">
                    <input type="text" formControlName="firstName" placeholder="first name"
                        [ngClass]="{ 'form-submitted': f.submitted }" />
                    <div *ngIf="ui.checkErrors(g.firstName, f)" class="errors">
                        <p *ngIf="g.firstName.errors?.required">First Name is Required</p>
                        <p *ngIf="g.firstName.errors?.maxlength">Firs name should not exceed {{
                            g.firstName.errors?.maxlength.requiredLength }} Characters</p>
                    </div>
                </div>

                <div class="input-container">
                    <input type="text" formControlName="lastName" placeholder="last name"
                        [ngClass]="{ 'form-submitted': f.submitted }" />
                    <div *ngIf="ui.checkErrors(g.lastName, f)" class="errors">
                        <p *ngIf="g.lastName.errors?.required">Last Name is Required</p>
                        
                    </div>
                </div>

                <div class="input-container">
                    <input type="email" formControlName="email" placeholder="Email"
                        [ngClass]="{ 'form-submitted': f.submitted }" />
                    <div *ngIf="ui.checkErrors(g.email, f)" class="errors">
                        <p *ngIf="g.email.errors?.required">Email is Required</p>
                        <p *ngIf="g.email.errors?.email">Enter valid Email</p>
                    </div>
                </div>

                <div class="input-container">
                    <input type="text" formControlName="contact" mask="(000) 000-0000" placeholder="Phone Number" maxlength="15"
                        [ngClass]="{ 'form-submitted': f.submitted }" />
                    <div *ngIf="ui.checkErrors(g.contact, f)" class="errors">
                        <p *ngIf="g.contact.errors?.required">Phone Number is Required</p>
                        <p *ngIf="g.contact.errors?.maxlength">Phone Number should not exceed
                            {{g.contact.errors?.maxlength.requiredLength}} Characters</p>
                    </div>
                </div>



                <div class="input-container">
                    <input type="password" formControlName="password" placeholder="Password"
                        [ngClass]="{ 'form-submitted': f.submitted }" />
                    <div *ngIf="ui.checkErrors(g.password, f)" class="errors">
                        <p *ngIf="g.password.errors?.required">Password is Required</p>
                    </div>
                </div>

                <div class="input-container">
                    <input type="password" formControlName="password_confirmation" placeholder="Confirmation Password"
                        [ngClass]="{ 'form-submitted': f.submitted }" />
                    <div *ngIf="ui.checkErrors(g.password_confirmation, f)" class="errors">
                        <p *ngIf="g.password_confirmation.errors?.required">Confirmation Password is Required</p>
                    </div>
                </div>

                <div class="input-container">
                    <input type="text" formControlName="address" placeholder="Address"
                        [ngClass]="{ 'form-submitted': f.submitted }" />
                    <!-- <div *ngIf="ui.checkErrors(g.house, f)" class="errors">
                        <p *ngIf="g.house.errors?.required">House is Required</p>
                    </div> -->
                </div>

                <div class="input-container">
                    <input type="text" formControlName="addressTwo" placeholder="Address Two"
                        [ngClass]="{ 'form-submitted': f.submitted }" />
                    <!-- <div *ngIf="ui.checkErrors(g.house, f)" class="errors">
                        <p *ngIf="g.house.errors?.required">House is Required</p>
                    </div> -->
                </div>

                <!-- <div class="input-container">
                    <input type="text" formControlName="street" placeholder="Street No"
                        [ngClass]="{ 'form-submitted': f.submitted }" />
                    <div *ngIf="ui.checkErrors(g.street, f)" class="errors">
                        <p *ngIf="g.street.errors?.required">Street is Required</p>
                    </div>
                </div> -->

                <div class="input-container">
                    <input type="text" formControlName="city" placeholder="City"
                        [ngClass]="{ 'form-submitted': f.submitted }" />
                    <div *ngIf="ui.checkErrors(g.city, f)" class="errors">
                        <p *ngIf="g.city.errors?.required">Ci is Required</p>
                    </div>
                </div>

                <!-- <div class="input-container">
                    <input type="text" formControlName="state" placeholder="State"
                        [ngClass]="{ 'form-submitted': f.submitted }" />
                    <div *ngIf="ui.checkErrors(g.city, f)" class="errors">
                        <p *ngIf="g.city.errors?.required">Ci is Required</p>
                    </div>
                </div> -->

                <div class="input-container">
                    <input type="text" formControlName="zipCode" placeholder="Zip Code"
                        [ngClass]="{ 'form-submitted': f.submitted }" />
                    <!-- <div *ngIf="ui.checkErrors(g.city, f)" class="errors">
                        <p *ngIf="g.city.errors?.required">Ci is Required</p>
                    </div> -->
                </div>

                <div class="terms-and-condition">
                    <input type="checkbox" formControlName="agreed_to_terms" (change)="onChange()" />
                    <p>I agree to the <a (click)="openModal(termsAndConditionModal)">Terms And Conditions</a></p>
                </div>

                <div class="input-container">
                    <button class="login" [disabled]="regForm.invalid"
                        [ngStyle]="{ opacity: loginLoading || regForm.invalid ? '0.5' : '1' }"
                        [ngClass]="loginLoading ? 'in-progress' : ''">Sign Up
                        <span></span><span></span><span></span></button>
                </div>
            </form>

            <div class="footer-container">
                <p>Already have account? <a routerLink="/login">Please Login</a></p>
            </div>
        </div>
    </div>
</div>

<ng-template #termsAndConditionModal>
    <div class="modal-div" *ngIf="!loadingStatus; else loader">
        <div [innerHtml]="htmlContent"></div>
        <div class="btns">
            <button type="button" class="m-2 terms-and-condition" *ngIf="!g.agreed_to_terms.value" (click)="agree()">
                Accpet
                <span></span>
                <span></span>
                <span></span>
            </button>
            <button type="button" class="m-2 terms-and-condition" (click)="modalRef.hide()">Cancel</button>
        </div>
    </div>
    <ng-template #loader>
        <app-component-loader></app-component-loader>
    </ng-template>
</ng-template>
